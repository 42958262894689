<script>
import NiceCounter from "@/components/NiceCounter.vue";
import Skills from "@/components/Skills.vue";
import TestimonialsCarousel from "@/components/TestimonialsCarousel.vue";

function daysSinceDate(string_t0) {
  let today = new Date();
  let t0 = new Date(string_t0);
  return Math.floor(
    (Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) -
      Date.UTC(t0.getFullYear(), t0.getMonth(), t0.getDate())) /
      (1000 * 60 * 60 * 24)
  );
}
export default {
  name: "About",
  components: {
    NiceCounter,
    Skills,
    TestimonialsCarousel,
  },
  methods: {
    pythonDays() {
      return daysSinceDate("2010/07/01");
    },
    espressoNumber() {
      return 1202 + 2 * daysSinceDate("2022/01/18");
    },
  },
};
</script>

<template>
  <section id="about" class="about">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>About</h2>
        <p>
          I have been working in the software industry for over ten years in a
          variety of contexts. I really enjoy writing clean beautiful code to
          solve concrete problems for my clients. I also enjoy training and
          mentoring fellow developers, and being active in the wider community.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-4">
          <img src="../assets/img/ablois_square.jpg" class="img-fluid" alt="" />
        </div>
        <div class="col-lg-8 pt-4 pt-lg-0 content">
          <h3>Web developer &amp; Python Expert</h3>
          <div class="row">
            <div class="col-lg-6">
              <p class="fst-italic">Me in a nutshell</p>
              <ul>
                <!-- <li>
                  <i class="bi bi-rounded-right"></i>
                  <strong>🎂 Birthday:</strong> 13 May
                </li> -->
                <li>
                  <strong>🌐 Website: </strong
                  ><a href="https://arnaudblois.com">https://arnaudblois.com</a>
                </li>
                <!-- <li>
                  <i class="bi bi-rounded-right"></i>
                  <strong>📱 Phone:</strong> +44 786 808 8137
                </li> -->
                <li>
                  <strong>📧 Email: </strong>
                  <a href="mailto:Arnaud%20Blois%20%3chi@arnaudblois.com%3e"
                    >hi@arnaudblois.com</a
                  >
                </li>
                <li>
                  <i class="bi bi-rounded-right"></i>
                  <strong>🎓 Degree: </strong>PhD
                </li>
                <li>
                  <i class="bi bi-rounded-right"></i>
                  <strong>🏠 City: </strong>London, UK
                </li>
                <li>
                  <strong>✅ Status: </strong>Available for contractual work.
                </li>
              </ul>
            </div>
            <div class="col-lg-6">
              <p class="fst-italic">Check out and support my projects</p>
              <ul>
                <li>
                  <strong> <i class="bi bi-github"></i> Github: </strong>
                  <a href="https://github.com/arnaudblois">
                    https://github.com/arnaudblois
                  </a>
                </li>
                <li>
                  <strong>❤️ Support my work: </strong>
                  <a href="https://www.buymeacoffee.com/arnaudblois">
                    Buy me a coffee
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <p>
            Feel free to contact me for any enquiry. You can also add me on
            LinkedIn, I'm always keen to connect with fellow professionals and
            recruiters. Also don't hesitate to give me a shout if you've created
            a Pull Request on any of my projects or have any suggestion for
            improvement. Cheers!
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Section -->

  <!-- ======= Skills Section ======= -->
  <Skills />
  <!-- End Skills Section -->

  <!-- ======= Facts Section ======= -->
  <section id="facts" class="facts">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Facts</h2>
        <p>A few key figures and fun facts about me.</p>
      </div>

      <div class="row counters">
        <div class="col-lg-3 col-6 text-center">
          <NiceCounter number="12" />
          <p>open-source projects on GitHub</p>
        </div>

        <div class="col-lg-3 col-6 text-center">
          <NiceCounter number="280007" />
          <p>lines of code published</p>
        </div>

        <div class="col-lg-3 col-6 text-center">
          <NiceCounter :number="pythonDays()" />
          <p>days using Python</p>
        </div>

        <div class="col-lg-3 col-6 text-center">
          <NiceCounter :number="espressoNumber()" />
          <p>espressos since 01/01/2021 ☕</p>
        </div>
      </div>
    </div>
  </section>
  <!-- End Facts Section -->

  <!-- ======= Testimonials Section ======= -->
  <section id="testimonials" class="testimonials">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Testimonials</h2>
        <p>
          The kind words of a few people I have had the pleasure to collaborate
          with on various projects along my career.
        </p>
      </div>

      <TestimonialsCarousel />
    </div>
  </section>
  <!-- End Testimonials Section -->
</template>

<style lang="scss">
@import "@/design";
.about {
  padding-top: $content-offset + 2rem;
  padding-bottom: 100px;

  .content {
    h3 {
      font-weight: 700;
      font-size: 26px;
      color: #555555;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        padding-bottom: 10px;
      }
      i {
        font-size: 20px;
        padding-right: 2px;
        color: #34b7a7;
      }
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.facts {
  padding-top: 2rem;
  .counters {
    span {
      font-size: 48px;
      display: block;
      color: $resume-sideline-color;
    }
    p {
      padding: 0;
      margin: 0 0 20px 0;
      font-family: "Raleway", sans-serif;
      font-size: 14px;
    }
  }
}
.skills {
  padding-top: 2rem;
}

.testimonials {
  padding-top: 2rem;
}
</style>
