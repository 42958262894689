<script>
import NiceCounter from "@/components/NiceCounter.vue";

export default {
  name: "About",
  components: {
    NiceCounter,
  },
};
</script>

<template>
  <!-- ======= Facts Section ======= -->
  <section id="facts" class="facts">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Facts</h2>
        <p>A few key figures and fun facts about me.</p>
      </div>
      <h3>ORIGINALS</h3>
      <div class="row counters">
        <div class="col-lg-3 col-6 text-center">
          <NiceCounter number="11" />
          <p>open-source projects on GitHub</p>
        </div>

        <div class="col-lg-3 col-6 text-center">
          <NiceCounter number="280007" />
          <p>lines of code published</p>
        </div>

        <div class="col-lg-3 col-6 text-center">
          <NiceCounter number="4189" />
          <p>days using Python</p>
        </div>

        <div class="col-lg-3 col-6 text-center">
          <NiceCounter number="1146" />
          <p>espressos drunk in 2021 ☕</p>
        </div>
      </div>
      <h3>In single column</h3>
      <div class="row counters">
        <div class="col-lg-3 col-xs-12 text-center">
          <NiceCounter number="11" />
          <p>open-source projects on GitHub</p>
        </div>

        <div class="col-lg-3 col-xs-12 text-center">
          <NiceCounter number="280007" />
          <p>lines of code published</p>
        </div>

        <div class="col-lg-3 col-xs-12 text-center">
          <NiceCounter number="4189" />
          <p>days using Python</p>
        </div>

        <div class="col-lg-3 col-xs-12 text-center">
          <NiceCounter number="1146" />
          <p>espressos drunk in 2021 ☕</p>
        </div>
      </div>
    </div>
  </section>
  <!-- End Facts Section -->
</template>

<style lang="scss">
@import "@/design";
.about {
  padding-top: $content-offset + 2rem;
  padding-bottom: 100px;

  .content {
    h3 {
      font-weight: 700;
      font-size: 26px;
      color: #555555;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        padding-bottom: 10px;
      }
      i {
        font-size: 20px;
        padding-right: 2px;
        color: #34b7a7;
      }
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.facts {
  padding-top: 2rem;
  .counters {
    span {
      font-size: 48px;
      display: block;
      color: $resume-sideline-color;
    }
    p {
      padding: 0;
      margin: 0 0 20px 0;
      font-family: "Raleway", sans-serif;
      font-size: 14px;
    }
  }
}
.skills {
  padding-top: 2rem;
}

.testimonials {
  padding-top: 2rem;
}
</style>
