<template>
  <section id="resume" class="resume">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Resume</h2>
        <p>
          Details of my career so far, over 10 years experience working on
          fascinating technical projects in sciences, fintech and foodtech.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <h3 class="resume-title">Summary</h3>
          <div class="resume-item pb-0">
            <h4>Arnaud Blois</h4>
            <p>
              <em>
                Professional software and web developer with a broad knowledge
                across the technology stack with a special expertise in Python,
                more than ten years experience delivering innovative solutions
                for start-ups and companies in the finance industry.
              </em>
            </p>
            <div>
              <ul>
                <li>London, UK</li>
                <!--<li>(+44) 786 808 8137</li>-->
                <li>
                  <a href="mailto:Arnaud%20Blois%20%3chi@arnaudblois.com%3e">
                    hi@arnaudblois.com
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <h3 class="resume-title">Education</h3>
          <div class="resume-item">
            <h4>PhD in Electrical &amp; Electronic Engineering</h4>
            <h5>2010 - 2014</h5>
            <p><em>UCL, London, UK</em></p>
            <ul>
              <li>
                Fabricated and studied very sensitive sensors (nanoSQUIDs) to
                assess materials of interest for the semiconductor industry.
              </li>
              <li>
                Created with Python a database of the devices, automatically
                collecting and parsing the corresponding data files with
                millions of data points.
              </li>
              <li>
                Wrote a Python program using Numpy and Scipy to automatically
                analyse large sets of raw magnetic data and extract useful
                signals in high-noise conditions, increasing the speed (× 10)
                and accuracy (× 2.5) of such analyses.
              </li>
              <li>
                Studied the statistical significance of the results of new
                measurement techniques using ANOVA (published in
                <a href="https://doi.org/10.1063/1.4843856"
                  >Blois et al, J. Appl. Phys. 114, 2013</a
                >)
              </li>
            </ul>
          </div>
          <div class="resume-item">
            <h4>MSc in Nanotechnology</h4>
            <h5>2008 - 2009</h5>
            <p><em>UCL, London, UK</em></p>
            <ul>
              <li>
                Double diploma with Supélec, main coursework included quantum
                physics, quantum computing, plasma electronics, nanofabrication
              </li>
              <li>Obtained with Distinction</li>
            </ul>
          </div>
          <div class="resume-item">
            <h4>MSc in Engineering</h4>
            <h5>2009 - 2010</h5>
            <p><em>Supélec, France</em></p>
          </div>
          <div class="resume-item">
            <h4>Master degree M1 in Economics and Management</h4>
            <h5>2006 - 2010</h5>
            <p><em>Supélec, France</em></p>
            <p>
              Double diploma with Supélec in distance learning at Jean Monet
              faculty school
            </p>
          </div>

          <h3 class="resume-title">SKILLS &amp; INTERESTS</h3>
          <div class="resume-item">
            <h4>Languages</h4>
            <ul>
              <li>French - mother tongue</li>
              <li>Spanish - B2</li>
              <li>Japanese - JPTL level 4</li>
            </ul>
          </div>
          <div class="resume-item">
            <h4>Interests</h4>
            <ul>
              <li>
                Hiking and backpacking (climbed Mount Fuji, Grand Canyon rim to
                Colorado)
              </li>
              <li>Tumbling and tricking</li>
              <li>
                Active member of Capoeira Club London, participating in public
                demonstrations and shows for charities
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6">
          <h3 class="resume-title">Professional Experience</h3>
          <div class="resume-item">
            <h4>Tech Lead - BehaviourLab</h4>
            <h5>Feb 2021 - Aug 2021</h5>
            <ul>
              <li>
                Led the Tech Team composed of five developers hired to deliver
                the online platform of a consultancy company specialised in
                debiasing financial decisions by fund managers.
              </li>
              <li>
                Liaise with management and stakeholders to establish the initial
                design, tech decisions and roadmaps to deploy the system on AWS
                with a focus on data security (ISO 27001)
              </li>
              <li>
                Organised the effort of the Data Science team to migrate their
                code base into source control, resulting in 25 packages linted
                and optimised with an average execution speed up of 750%.
              </li>
            </ul>
          </div>
          <div class="resume-item">
            <h4>Vice-President - Goldman Sachs</h4>
            <h5>Jan 2020 - Feb 2021</h5>
            <ul>
              <li>
                Team lead for Python Engineering, an Agile team dedicated to
                provide firmwide support, efficient tooling and methodology to
                the thousands of Python users at GS.
              </li>
              <li>
                Coordinated the "Python Happy Path" initiative, a collaboration
                of several tech teams aiming to deliver a smooth user experience
                for common workflows, such as distributed scheduling with
                Rays.io and Dask, Flask/Django APIs on Kubernetes, notebook
                sharing with JupyterHub.
              </li>
              <li>
                Engaged with users, stakeholders and senior management,
                establishing the roadmap and drafting the OKRs and JIRA tickets.
              </li>

              <li>
                Implemented cookiecutter templates and fully automated CI/CD
                pipelines for GitLab for Python package upload and
                Kubernetes-based API, which led to a 600% increase in deployment
                speed.
              </li>
              <li>
                Contributed over ten packages to the GS Python Community used
                throughout the organisation.
              </li>
              <li>Mentored and trained two apprentices and several interns</li>
            </ul>
            <h4>Associate - Goldman Sachs</h4>
            <h5>May 2018 - Jan 2020</h5>
            <ul>
              <li>
                Joined the SDLC department as their Python expert to answer the
                growing demand for centralised support, and played a pivotal
                role in establishing and consolidating Python Engineering.
              </li>
              <li>
                Designed the automated build process for the optimised Python
                binaries and the GS Docker/K8s images distributed firmwide.
              </li>
              <li>
                Created and deployed a firmwide system (Django Rest API,
                PostgreSQL, K8s) handling Python package upload to the central
                Pypi repository, enforcing security and software quality checks.
                This enabled about 20 teams to share over 100 packages for the
                first time.
              </li>
              <li>
                Got recognized as a “Culture Pioneer”: volunteered as team
                captain for Community Team Work, created and animated the GS
                Python Community, organised bake sales in profit of Cool Earth.
              </li>
            </ul>
          </div>
          <div class="resume-item">
            <h4>Python Engineer - Origin Market</h4>
            <h5>March 2017 - May 2018</h5>
            <ul>
              <li>
                Worked on the development, maintenance and deployment of the
                Origin platform, a system designed to make the bond issuance
                process more efficient.
              </li>
              <li>
                Developed several major customer-facing features from the
                ground-up in Python and Django including parsing and aggregating
                financial data from different sources (Bloomberg, IFR, Tullets,
                rating agencies), a workflow dashboard for clients, event
                monitoring and statistics.
              </li>
              <li>
                Contributed at times to front-end (Angular.js) and devops
                (GitLab, Docker, Ansible) developments.
              </li>
              <li>
                Championed software quality and best coding practices (PEP8,
                DRY, KISS), driving the adoption of linting as part of our
                CI/CD. Within six months the entire code base had reached
                10.0/10.0 on Pylint.
              </li>
              <li>
                Authored more than 20% of the back-end code on the Git
                repository in less than a year, including a full testing
                framework with very high coverage.
              </li>
            </ul>
          </div>
          <div class="resume-item">
            <h4>CTO &amp; Cofounder - Dinameal</h4>
            <h5>Apr 2014 – Mar 2017</h5>
            <ul>
              <li>
                Co-founded a supply optimisation system reducing food wastage,
                which ranked first French project and eighth globally in the
                contest "100 projects for the climate".
              </li>
              <li>
                Contributed to the full technical stack from front-end (HTML,
                CSS, JavaScript with jQuery) to deployment (NginX, Gunicorn,
                Redis, Celery) with a stronger focus on the backend written in
                Python (Django-REST API).
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Resume",
};
</script>

<style lang="scss">
@import "@/design";
.resume {
  padding-top: $content-offset;
  padding-bottom: 100px;
  .resume-title {
    font-size: 26px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
    color: $header-title-color;
  }
  .resume-item {
    padding: 0 0 20px 20px;
    margin-top: -2px;
    border-left: 2px solid $resume-sideline-color;
    position: relative;
  }
  .resume-item h4 {
    line-height: 18px;
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: $resume-title-color;
    margin-bottom: 10px;
  }
  .resume-item h5 {
    font-size: 16px;
    background: $resume-date-color;
    padding: 5px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
    color: $resume-sideline-color;
  }
  .resume-item ul {
    padding-left: 20px;
  }
  .resume-item ul li {
    padding-bottom: 10px;
  }
  .resume-item:last-child {
    padding-bottom: 0;
  }
  .resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #fff;
    border: 2px solid $resume-sideline-color;
  }
}
</style>
