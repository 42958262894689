<template>
  <span class="nicecounter">{{ display_number }}</span>
</template>

<script>
export default {
  name: "NiceCounter",
  props: {
    number: String,
  },
  data() {
    return {
      display_number: 0,
      seen: false,
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      root: null,
      threshold: 1.0,
    });
    this.observer.observe(this.$el);
  },
  methods: {
    refresh(step_size, i) {
      this.display_number = Math.floor(i * step_size);
    },
    equalize() {
      this.display_number = this.number;
    },
    onElementObserved(entries) {
      entries.forEach((entry) => {
        if (this.seen || entry.intersectionRatio < 1) {
          return;
        }
        this.display_number = 0;
        const delay = 20;
        const duration = 1100;
        const num_of_steps = Math.floor(duration / delay);
        const step_size = Number(this.number) / num_of_steps;

        for (let i = 0; i < num_of_steps; i++) {
          setTimeout(this.refresh, delay * i, step_size, i);
        }
        setTimeout(this.equalize, duration);
        this.seen = true;
        this.observer.disconnect();
      });
    },
  },
};
</script>

<style></style>
