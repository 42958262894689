<template>
  <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Contact</h2>
        <p>
          I am always keen to discuss interesting projects and opportunities so
          do not hesitate to get in touch with me using the contact details
          below. I am looking forward to connecting with you :)
        </p>
      </div>

      <div>
        <iframe
          style="border: 0; width: 100%; height: 270px"
          src="https://maps.google.com/maps?q=Kentish%20town&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>

      <div class="row mt-5">
        <div class="col-lg-4 mt-3">
          <div class="info">
            <div class="address">
              <i class="bi bi-geo-alt"></i>
              <h4>Location:</h4>
              <p>Kentish Town, London</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-3">
          <div class="info">
            <div class="email">
              <i class="bi bi-envelope"></i>
              <h4>Email:</h4>
              <p>
                <a href="mailto:Arnaud%20Blois%20%3chi@arnaudblois.com%3e">
                  hi@arnaudblois.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <!--
        <div class="col-lg-4 mt-3">
          <div class="info">
            <div class="phone">
              <i class="bi bi-phone"></i>
              <h4>Call:</h4>
              <p>+44 786 808 8137</p>
            </div>
          </div>
        </div>
        -->
        <!--
        <div class="col-lg-8 mt-5 mt-lg-0">
          <form
            action="forms/contact.php"
            method="post"
            role="form"
            class="email-form"
          >
            <div class="row">
              <div class="col-md-6 form-group">
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  id="name"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div class="col-md-6 form-group mt-3 mt-md-0">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  required
                />
              </div>
            </div>
            <div class="form-group mt-3">
              <input
                type="text"
                class="form-control"
                name="subject"
                id="subject"
                placeholder="Subject"
                required="required"
              />
            </div>
            <div class="form-group mt-3">
              <textarea
                class="form-control"
                name="message"
                rows="5"
                placeholder="Message"
                required="required"
              ></textarea>
            </div>
            <div class="my-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">
                Your message has been sent. Thank you!
              </div>
            </div>
            <div class="text-center">
              <button type="submit">Send Message</button>
            </div>
          </form>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<style lang="scss">
@import "@/design";
.contact {
  padding-top: $content-offset;
  padding-bottom: 70px;
  .info {
    width: 100%;
    background: #fff;
    i {
      font-size: 20px;
      color: #34b7a7;
      float: left;
      width: 44px;
      height: 44px;
      background: #effbf9;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      transition: all 0.3s ease-in-out;
    }
    h4 {
      padding: 0 0 0 60px;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 5px;
      color: #222222;
    }
    p {
      padding: 0 0 0 60px;
      margin-bottom: 0;
      font-size: 14px;
      color: #555555;
    }
  }

  .info .email:hover i,
  .info .address:hover i,
  .info .phone:hover i {
    background: #34b7a7;
    color: #fff;
  }
  .email-form {
    width: 100%;
    background: #fff;
  }
  .contact .email-form .form-group {
    padding-bottom: 8px;
  }
  .email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  .email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  .email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  .email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  .email-form input,
  .email-form textarea {
    border-radius: 4px;
    box-shadow: none;
    font-size: 14px;
  }
  .email-form input:focus,
  .email-form textarea:focus {
    border-color: #34b7a7;
  }
  .email-form input {
    height: 44px;
  }
  .email-form textarea {
    padding: 10px 12px;
  }
  .email-form button[type="submit"] {
    background: #34b7a7;
    border: 0;
    padding: 10px 30px 12px 30px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
  }
  .email-form button[type="submit"]:hover {
    background: #3dc8b7;
  }
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
