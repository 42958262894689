<script>
export default {
  name: "Blog",
  components: {},
};
</script>

<template>
  <section id="blog" class="blog">
    <div class="container" data-aos="fade-up">
      <div class="section-title">
        <h2>Blog Posts</h2>
        <p>
          Different pieces I have written about technology, career development
          and my other random topics of interest.
        </p>
      </div>

      <div class="row">
        <img
          src="../assets/img/coming-soon.jpg"
          class="col-lg-6 mx-auto"
          alt="coming soon"
        />
      </div>
    </div>
  </section>
</template>

<style lang="scss">
@import "@/design";
.blog {
  padding-top: $content-offset;
  padding-bottom: 100px;

  .coming-soon: {
    max-width: 50px;
  }
  .item img {
    width: 100%;
    height: auto;
  }

  .content {
    h3 {
      font-weight: 700;
      font-size: 26px;
      color: #555555;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        padding-bottom: 10px;
      }
      i {
        font-size: 20px;
        padding-right: 2px;
        color: #34b7a7;
      }
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}

.facts .counters {
  span {
    font-size: 48px;
    display: block;
    color: $resume-sideline-color;
  }
  p {
    padding: 0;
    margin: 0 0 20px 0;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
  }
}
</style>
